<template>
  <carousel class="screen" :autoplay="30000" :wrapAround="true">
    <slide v-for="item in slides" :key="item">
      <div v-if="item['type'] === 'room'" class="grid-container room-slide">
        <div class="item1">
          <div class="visual__container">
            <div class="visual__noise" :data-type="item['imageNoise']"></div>
            <div class="visual">
              <img :src="item['image']" alt="Slide Image">
            </div>
          </div>
        </div>
        <div class="item2">
          Prochaine session<br>disponible pour<br>
          <div class="titre">{{ item['name'] }}</div>
        </div>
        <div class="item3">
          <p :style="item['nextSession'] === null ? 'font-size: 4.9vw!important;' : 'font-size: 10vw!important; margin: 0;'">
            {{ item['nextSession'] === null ? "Il n'y a plus de session aujourd'hui" : item['nextSession'] }}
          </p>
        </div>
      </div>
      <img v-else :src="item['image']" alt="Slide Image" class="pubimg">
    </slide>
  </carousel>

</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide} from 'vue3-carousel';
import axios from "axios";

export default {
  name: 'ScreenView',
  components: {
    Slide,
    Carousel,
  },
  data() {
    return {
      slides: [],
      gamesInd: [],
      activeSlide: 0,
      revisionCount: 0, // to see if the configuration changed
      intervalUpdateCheck: null,
      intervalUpdateGame: null,
      directus_url: "https://admin.casejeux.com",
      getout_url: "https://getout-angers.4escape.io/booking-data-json",
      game_url: "https://api.casejeux.com/games"
    }
  },
  methods: {
    async updateSlides() {
      let response = await axios.get(`${this.directus_url}/items/Ecran/1?fields=order.item.*`);
      let slides = [];
      this.gamesInd = [];
      let i = 0;
      for (let item of response['data']['data']['order']
          .filter(a => (a['item']['start'] === null || new Date(a['item']['start'].split('T')[0]) <= new Date()) && // Start date is passed
              (a['item']['end'] === null || new Date() <= new Date(a['item']['end'].split('T')[0]))) // End date not passed
          ) {
        let new_slide = {};
        new_slide['type'] = item['item']['type'];
        new_slide['assetId'] = item['item']['assetId'];
        new_slide['name'] = item['item']['name'];
        if (new_slide['type'] === 'game') {
          new_slide['image'] = `${this.game_url}?time=${new Date().getTime()}`
          this.gamesInd.push(i);
        } else {
          new_slide['image'] = `${this.directus_url}/assets/${item['item']['image']}`;
        }
        new_slide['imageNoise'] = this.getRandomInt(1, 4);
        new_slide['nextSession'] = null;
        slides.push(new_slide);
        i++;
      }
      this.slides = slides;
    },
    async configChanged() {
      let response = await axios.get( `${this.directus_url}/revisions?search=Ecran&meta=total_count&limit=0`);
      if (response['data']['meta']['total_count'] !== this.revisionCount) {
        this.revisionCount = response['data']['meta']['total_count'];
        await this.updateSlides();
      }
      await this.updateRoomNextSession();
    },
    async updateRoomNextSession() {
      let todayDate = new Date().toISOString().split('T')[0];
      let response = await axios.post(this.getout_url, {
            'date': todayDate,
            'viewDuration': 1
          }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
      );
      for (let slide of this.slides.filter(a => a['type'] === 'room')) {
        let array = response['data']['results'];
        array = array.filter(a => a['roomId'] === slide['assetId'] && // Is it the room I'm looking for
            a['start'].split(' ')[0] === todayDate && // The session is today
            new Date(a['start']) >= new Date() && // The session is not passed
            (!a['booked'] && !a['disabled'])); // The session is available
        array = array.sort(function(b, c){return new Date(b['start']) - new Date(c['start'])}); // Sort the session to get the next one
        if (array.length === 0) {
          // There is no available session
          slide['nextSession'] = null;
        } else {
          // Select the next available session
          slide['nextSession'] = new Date(array[0]['start']).toLocaleTimeString('fr-FR',{hour: '2-digit', minute:'2-digit'});
        }
      }
    },
    async updateGamesImage() {
      for (let ind of this.gamesInd) {
        this.slides[ind]["image"] = `${this.game_url}?time=${new Date().getTime()}`;
      }
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    }
  },
  async mounted() {
    document.title = this.$route.name + " - Get Out Angers";
    await this.configChanged();
    this.intervalUpdateCheck = setInterval(this.configChanged, 60000);
    this.intervalUpdateGame = setInterval(this.updateGamesImage, 300000);
  },
  beforeUnmount() {
    clearInterval(this.intervalUpdateCheck);
    clearInterval(this.intervalUpdateGame);
  }
}
</script>
