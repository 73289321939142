import { createRouter, createWebHistory } from 'vue-router'
import ScreenView from '@/views/Screen.vue'
import MenuView from '@/views/Menu.vue'
import MenuMansView from '@/views/MenuMans.vue'
import AllergenesView from '@/views/Allergene.vue'
import ViandeView from  '@/views/Origine_viande.vue'
import AdminView from '@/views/Admin.vue'

const routes = [
    {
        path: '/',
        name: 'Admin',
        component: AdminView
    },
    {
        path: '/ecran',
        name: 'Écran',
        component: ScreenView
    },
    {
        path: '/menu',
        name: 'Menu',
        component: MenuView
    },
    {
        path: '/menu_mans',
        name: 'MenuMans',
        component: MenuMansView
    },
    {
        path: '/allergenes',
        name: 'Allergènes',
        component: AllergenesView
    },
    {
        path: '/origine_viandes',
        name: 'Origine des viandes',
        component: ViandeView
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
