import { createApp } from "vue";
import App from "./App.vue";

// Router

import router from "./router";

// PWA Worker

//import './registerServiceWorker'

// Style

import "@/assets/css/style.css";
//import "@/assets/css/tailwind.css"

// Mount the app

// Create a new store instance.

createApp(App).use(router).mount("#app");
