<template>
  <MyNavbar :url="getUrl()" />
  <CCard style="margin-top: 16px">
    <CCardBody>
      <CCardTitle>Liste des allergènes</CCardTitle>
      <CCardText>
        <CTable :columns="columns" :items="rows" bordered reponsive striped class="custom-table"/>
      </CCardText>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '@/assets/image/logo_angers_white.png';
import {CCard, CTable, CCardBody, CCardText, CCardTitle} from "@coreui/vue";
import MyNavbar from "@/component/MyNavbar.vue";

export default {
  name: 'AllergenesView',
  components: {
    MyNavbar,
    CCardText,
    CCardTitle,
    CCardBody,
    CTable,
    CCard
  },
  data() {
    return {
      logo,
      columns: [
        {
          key: "allergene",
          label: "Allergène",
          _cellProps: { id: { scope: 'row' } },
        }
      ],
      rows: [],
      directus_url: "https://admin.casejeux.com",
    }
  },
  methods: {
    getIndexesVal(array, elem) {
      let indexes = [];
      for (let e of array) {
        if (e["id"] === elem) {
          indexes.push(true);
        } else {
          indexes.push(false);
        }
      }
      return indexes;
    },
    fillRow(item, i) {
      for (let al of item) {
        let indexes = this.getIndexesVal(this.rows, parseInt(al["item"]));
        let j = 0;
        for (let val of indexes) {
          if (val) {
            this.rows[j][i] = "🮱";
          } else {
            this.rows[j][i] = " ";
          }
          j++;
        }
      }
    },
    getAllergenes() {
      axios.get(`${this.directus_url}/items/Type_Allergene`).then(response => {
        for (let item of response['data']['data']) {
          this.rows.push({
            id: item["id"],
            allergene: item["Text"],
            _cellProps: { id: { scope: 'row' } },
          });
        }
      }).then(() => {
        axios.get(`${this.directus_url}/items/Allergene?fields=*.*`).then(response => {
          let i = 1;
          for (let item of response['data']['data']) {
            this.columns.push({
              key: i,
              label: item["Produit"],
              _props: { scope: 'col' },
            })
            this.fillRow(item["Allergenes"], i);
            i++;
          }
        })
      });
    },
    getUrl() {
      return localStorage.getItem("url");
    }
  },
  mounted() {
    document.title = this.$route.name + " - " + localStorage.getItem("sitename");
    this.getAllergenes();
  }
}
</script>
<style>
.custom-table tr:last-child > td {
  border: solid 1px rgb(222, 226, 230) !important;
}
</style>