<template>
  <CNavbar expand="lg" color-scheme="dark" class="bg-dark" style="padding: 0;">
    <CContainer fluid>
      <CNavbarBrand :href="url" style="padding: 0;">
        <img :src="logo" alt="" style="height: 9vh"/>
      </CNavbarBrand>
      <CNavbarToggler aria-label="Toggle navigation" aria-expanded={visible} @click="visible = !visible"/>
      <CCollapse class="navbar-collapse" :visible="visible">
        <CNavbarNav class="d-flex">
          <CNavItem>
            <CNavLink href="/origine_viandes">
              Origine des viandes
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="/allergenes">
              Liste des allergènes
            </CNavLink>
          </CNavItem>
        </CNavbarNav>
      </CCollapse>
    </CContainer>
  </CNavbar>
</template>
<script>
import logo from '@/assets/image/logo_angers_white.png';
import {
  CCollapse, CContainer, CNavbar, CNavbarBrand,
  CNavbarNav,
  CNavbarToggler,
  CNavItem,
  CNavLink,
} from "@coreui/vue";

export default {
  name: 'MyNavbar',
  components: {
    CCollapse,
    CNavbarToggler,
    CNavLink,
    CNavItem,
    CNavbarNav,
    CNavbarBrand,
    CContainer,
    CNavbar
  },
  data() {
    return {
      logo,
      visible: false,
    }
  },
  props: {
    url: String
  }
}
</script>