<template>
  <span :class="'icon-' + this.$props.iconValue">
    <span v-for="n in this.nbPath[this.$props.iconValue]" :key="n" :class="'path'+n"></span>
  </span>
</template>
<script>
  export default {
    name: 'MenuIcon',
    props: {
      iconValue: {
        type: String,
        required: true,
        default: 'AB'
      }
    },
    data() {
      return {
        nbPath: {
          'red_w': 2,
          'rose_w': 2,
          'spar_w': 5,
          'white_w': 2,
          'AB': 7,
          'FT': 3,
          'PA': 7,
          'cc': 5
        }
      }
    },
  }
</script>