<template>
  <MyNavbar :url="getUrl()" />
  <CCard style="margin-top: 16px">
    <CCardBody>
      <CCardTitle>Origine des viandes</CCardTitle>
      <CCardText>
        <CTable :columns="columns" :items="rows" bordered reponsive striped class="custom-table"/>
      </CCardText>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '@/assets/image/logo_angers_white.png';
import {CCard, CTable, CCardBody, CCardText, CCardTitle} from "@coreui/vue";
import MyNavbar from "@/component/MyNavbar.vue";

export default {
  name: 'ViandeView',
  components: {
    MyNavbar,
    CCardText,
    CCardTitle,
    CCardBody,
    CTable,
    CCard,
  },
  data() {
    return {
      logo,
      columns: [
        {
          key: "produit",
          label: "Produit",
          _cellProps: { id: { scope: 'row' } },
        },
        {
          key: "ne",
          label: "Né",
          _props: { scope: 'col' },
        },
        {
          key: "eleve",
          label: "Élevé",
          _props: { scope: 'col' },
        },{
          key: "abattu",
          label: "Abattu",
          _props: { scope: 'col' },
        },
      ],
      rows: [],
      directus_url: "https://admin.casejeux.com",
    }
  },
  methods: {
    getOrigines() {
      axios.get(`${this.directus_url}/items/Origine_Viandes`).then(response => {
        for (let item of response['data']['data']) {
          this.rows.push({
            produit: item["Produit"],
            ne: item["Ne"],
            eleve: item["Eleve"],
            abattu: item["Abattu"],
            _cellProps: { id: { scope: 'row' } },
          });
        }
      });
    },
    getUrl() {
      return localStorage.getItem("url");
    }
  },
  mounted() {
    document.title = this.$route.name + " - " + localStorage.getItem("sitename");
    this.getOrigines();
  }
}
</script>
<style>
.custom-table tr:last-child > td {
  border: solid 1px rgb(222, 226, 230) !important;
}
</style>