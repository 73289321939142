<template>
  <CAccordionItem ref="AccordionItemGenerated" style="border-radius: 0;" data-loaded="false" @click="loadItems()">
    <CAccordionHeader>
      {{ item.name }}
      &nbsp;
      <MenuIcon v-for="icon in item.ethical" :key="icon" :icon-value="icons[icon]"/>
    </CAccordionHeader>
    <CAccordionBody>
      <CTable v-if="item.collection === 'standard_categories'">
        <CTableBody>
          <CTableRow v-for="el in products" :key="el">
            <CTableDataCell>{{ el.name }}
              <br v-if="el.description !== null">
              <div v-if="el.description !== null" class="description">
                {{ el.description }}
              </div>
            </CTableDataCell>
            <CTableDataCell>
              <MenuIcon v-for="icon in el.ethical" :key="icon" :icon-value="icons[icon]"/>
            </CTableDataCell>
            <CTableDataCell>{{ el.size }}</CTableDataCell>
            <CTableDataCell>{{ el.price }}</CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
      <CTable v-else>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col"></CTableHeaderCell>
            <CTableHeaderCell scope="col">Verre (12cL)</CTableHeaderCell>
            <CTableHeaderCell scope="col">Bouteille (75cL)</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="el in products" :key="el">
            <CTableDataCell>
              <MenuIcon :icon-value="wine_icons[el.icon]"/>
              {{ el.name }}
              <br v-if="el.description !== null">
              <div v-if="el.description !== null" class="description">
                {{ el.description }}
              </div>
            </CTableDataCell>
            <CTableDataCell>{{ el.price_glass }}</CTableDataCell>
            <CTableDataCell>{{ el.price_bottle }}</CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
    </CAccordionBody>
  </CAccordionItem>
</template>
<script>
import {CAccordionBody, CAccordionHeader, CAccordionItem, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow} from "@coreui/vue";
import MenuIcon from "@/component/Icon.vue";
import axios from "axios";

export default {
  name: 'MyAccordionItem',
  components: {
    MenuIcon,
    CTableHeaderCell,
    CTableHead,
    CTableDataCell,
    CTableRow,
    CTableBody,
    CTable,
    CAccordionBody,
    CAccordionHeader,
    CAccordionItem
  },
  data() {
    return {
      icons: ["AB", "PA", "cc", "FT"],
      wine_icons: ["white_w", "rose_w", "red_w", "spar_w"],
      products: [],
      directus_url: "https://admin.casejeux.com",
    }
  },
  props: {
    item: {
      type: Object,
    }
  },
  methods: {
    async loadItems() {
      if (this.$refs.AccordionItemGenerated.$el.getAttribute("data-loaded") === 'false') {
        let response = await axios.get(`${this.directus_url}/items/${this.item['collection']}/${this.item['id']}?fields=items.item.*`);
        let products = [];
        for (let item of response['data']['data']['items']) {
          let new_product = {};
          new_product['name'] = item['item']['nom'];
          new_product['ethical'] = item['item']['categories_ethiques'] ? item['item']['categories_ethiques'].toSorted() : null;
          new_product['description'] = item['item']['description'];
          new_product['size'] = item['item']['taille'];
          new_product['price'] = item['item']['prix'] ? item['item']['prix'].toFixed(2).replace(".", ",") + ' €' : undefined;
          new_product['icon'] = item['item']['icon'];
          new_product['price_glass'] = item['item']['prix_verre'] ? item['item']['prix_verre'].toFixed(2).replace(".", ",") + ' €' : undefined;
          new_product['price_bottle'] = item['item']['prix_bouteille'] ? item['item']['prix_bouteille'].toFixed(2).replace(".", ",") + ' €' : undefined;
          products.push(new_product);
        }
        this.products = products;
        this.$refs.AccordionItemGenerated.$el.setAttribute("data-loaded", true);
      }
    },
  }
}
</script>
