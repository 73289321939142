<template>
  <MyNavbar url="/menu" />
  <CCard ref="accordion" class="border-dark-subtle"
         style="border-radius: 0; margin-top: 0.5rem;">
    <CAccordion>
      <MyAccordionItem v-for="el in items" :key="el" :item="el" />
    </CAccordion>
  </CCard>
  <CCard ref="footer-ref" class="border-dark-subtle" style="bottom: 0; position: fixed; width: 100%;border-radius: 0;text-align: center;">
    <CCardBody style="border-radius: 0;">
      <CContainer>
        <CRow>
          <CCol>
            <CCardText><MenuIcon icon-value="AB"/> Bio</CCardText>
          </CCol>
          <CCol>
            <CCardText><MenuIcon icon-value="PA"/> Local</CCardText>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCardText><MenuIcon icon-value="cc"/> Circuit Court</CCardText>
          </CCol>
          <CCol>
            <CCardText><MenuIcon icon-value="FT"/> Issu du Commerce Équitable</CCardText>
          </CCol>
        </CRow>
      </CContainer>
    </CCardBody>
    <CCardFooter style="border-radius: 0;">
      <CCardText>Prix nets (€ TTC)</CCardText>
    </CCardFooter>
  </CCard>
</template>

<script>
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '@/assets/image/logo_angers_white.png';
import {CAccordion, CContainer, CCard, CCardBody, CCardText, CCardFooter, CCol, CRow} from "@coreui/vue";
import MenuIcon from "@/component/Icon.vue";
import MyAccordionItem from "@/component/MyAccrodionItem.vue";
import MyNavbar from "@/component/MyNavbar.vue";

export default {
  name: 'MenuView',
  components: {
    MyNavbar,
    MyAccordionItem,
    MenuIcon,
    CCol,
    CRow,
    CCardFooter,
    CCardText,
    CCardBody,
    CCard,
    CContainer,
    CAccordion
  },
  data() {
    return {
      logo,
      icons: ["AB", "PA", "cc", "FT"],
      items: [],
      directus_url: "https://admin.casejeux.com",
    }
  },
  methods: {
    async getCategories() {
      let response = await axios.get(`${this.directus_url}/items/Menu/1?fields=order.*.*`);
      let categories = [];
      for (let item of response['data']['data']['order']) {
        let new_category = {};
        new_category['id'] = item['item']['id'];
        new_category['name'] = item['item']['nom'];
        new_category['ethical'] = item['item']['categories_ethiques'] ? item['item']['categories_ethiques'].toSorted() : null;
        new_category['collection'] = item['collection'];
        new_category['items'] = [];
        categories.push(new_category);
      }
      this.items = categories;
    },
  },
  async mounted() {
    localStorage.setItem("url", "/menu");
    localStorage.setItem("sitename", "Get Out Angers");
    document.title = this.$route.name + " - Get Out Angers";
    this.$refs["accordion"].$el.style.marginBottom = 'calc(' + this.$refs['footer-ref'].$el.clientHeight + 'px + 1rem)';
    await this.getCategories();
  },
}
</script>
<style>
.table {
  margin: 0;
  vertical-align: middle;
  text-align: center;
}
td > .description {
  font-size: smaller;
  color: #424b57;
  margin-top: -0.05em;
}
td:first-child {
  text-align: left;
}
tr:last-child > td {
  border: none;
}
.accordion-button {
  text-transform: uppercase;
  font-weight: 700;
}
@media only screen and (max-width: 576px) {
  html {
    font-size: 14px;
  }
}
</style>
